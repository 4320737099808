import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import SidebarMenu from '../SidebarMenu';
import LightBox from '../../components/LightBox';
import CookieBanner from '../../components/CookieBanner';
import '../../styles/index.scss';
import layoutStyles from './layout.module.scss';


const Layout = (props) => {
    return (
        <React.Fragment>
        <LightBox/>
        <SidebarMenu/>
        <div id="mainLayout" className={layoutStyles.layoutcontainer}>
            <Header />
            <div className={props.home ? layoutStyles.homelayoutwrapper : layoutStyles.layoutwrapper}>{props.children}</div>
            <Footer />
        </div>
        <CookieBanner />
        </React.Fragment>
        );
}
export default Layout;