import React, {useEffect, useState, useCallback} from 'react';
import SidebarForm from '../SidebarForm';
import { lighBoxVisibility, lightboxDelayTime, millisecondsInDays } from './helpers';
import lightboxStyles from './lightbox.module.scss';

const LightBox = () => {

    const [visibility, setVisibility] = useState(lighBoxVisibility.HIDDEN);


    const handleCloseButton = () => {
        setVisibility(lighBoxVisibility.HIDDEN);
    }

    const displayLightBox = () => {
        setTimeout(() => {
            window.localStorage.setItem('lightboxmwtLastDisplay',  new Date());
            setVisibility(lighBoxVisibility.VISIBLE); 
        }, lightboxDelayTime);
    }

    const validateeDisplay = useCallback(() => {
        if(!window.localStorage.getItem('lightboxmwtLastDisplay')) {
            displayLightBox()
            return;
        }
        const data = window.localStorage.getItem('lightboxmwtLastDisplay');
        const date = new Date(data).getTime();
        const currentDate = new Date().getTime();
        const difference = currentDate - date;
        if (difference > millisecondsInDays) {
            displayLightBox();
            return;
        }
    }, []);

    useEffect(() => {
        validateeDisplay();
    }, [validateeDisplay]);


    switch(visibility) {
        case lighBoxVisibility.HIDDEN:
            return null;
        case lighBoxVisibility.VISIBLE: 
            return (
                <div className={lightboxStyles.lightboxcontainer}>
                    <div className={lightboxStyles.wrapper}>
                    <button className={lightboxStyles.closebutton} onClick={handleCloseButton}>X CLOSE</button>
                        <div className={lightboxStyles.innercontainer}>
                            <div className={lightboxStyles.textcontainer}>
                                 <h1>Abonează-te la newsletterul MintWebTuts</h1>
                                 <p>Dacă îți place ce ai găsit aici și vrei să fii la curent cu ultimele articole atunci
                                     nu ezita să te abonezi și îți voi trimite săptămânal articole despre programare, dezvoltare web și multe altele.
                                 </p>
                                 <SidebarForm/>
                            </div>
                            <div className={lightboxStyles.imagecontainer} />
                        </div>
                    </div>
                </div>
            );   
        default:
            return null;    
    }
}
export default LightBox;