import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import footerStyles from './footer.module.scss';


const Footer = () => {

    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title,
                description,
                author
            }
        }
    }
    `)


    return (
        <div className={footerStyles.container}>
            <div className={footerStyles.footerwrapper}>
                <span className={footerStyles.footerlogo}>Mint<span>Web</span>Tuts</span>
                <span> - developed by {data.site.siteMetadata.author}</span>
            </div>
        </div>
    );
};
export default Footer;
