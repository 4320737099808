import React, { useState } from 'react';
import sidebarFormStyles from './sidebarform.module.scss';

const SidebarForm = () => {

    const [nameValue, setName] = useState('');
    const [emailValue, setEmail] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };


    return (
        <div className={sidebarFormStyles.sidebarformcontainer}>
            <form method="post" className="af-form-wrapper" accept-charset="UTF-8" action="https://www.aweber.com/scripts/addlead.pl"  >
                <div style={{display: 'none'}}>
                <input type="hidden" name="meta_web_form_id" value="2103824270" />
                <input type="hidden" name="meta_split_id" value="" />
                <input type="hidden" name="listname" value="awlist5948061" />
                <input type="hidden" name="redirect" value="https://www.aweber.com/thankyou-coi.htm?m=text" id="redirect_8b6f7a37d1c8ac3deae8ac1f2727b3d8" />

                <input type="hidden" name="meta_adtracking" value="My_Web_Form" />
                <input type="hidden" name="meta_message" value="1" />
                <input type="hidden" name="meta_required" value="name,email" />

                <input type="hidden" name="meta_tooltip" value="" />
                </div>
                <div id="af-form-2103824270" className="af-form"><div id="af-body-2103824270" className="af-body af-standards">
                <div className="af-element">
                <label className="previewLabel" for="awf_field-110783581">Nume: </label>
                <div className="af-textWrap">
                <input id="awf_field-110783581" className={sidebarFormStyles.inputText} type="text" name="name" value={nameValue}  onChange={handleNameChange}  tabIndex={-2} />
                </div>
                <div className="af-clear"></div></div>
                <div className="af-element">
                <label className="previewLabel" for="awf_field-110783582">Email: </label>
                <div className="af-textWrap">
                    <input id="awf_field-110783582" className={sidebarFormStyles.inputText} type="text" name="email" value={emailValue} onChange={handleEmailChange} tabIndex={-3}/>
                </div>
                <div className="af-clear"></div>
                </div>
                <div className="af-element buttonContainer">
                    <input name="submit" className= {sidebarFormStyles.submitButton}type="submit" value="Ma abonez" tabIndex={-3} />
                <div className="af-clear"></div>
                </div>
                </div>
                </div>
                <div style={{display: 'none'}}><img src="https://forms.aweber.com/form/displays.htm?id=TIwMzBxMLEzsDA==" alt="" /></div>
            </form>
        </div>
    );
}
export default SidebarForm;