import React, { useEffect, useState } from 'react';
import { Link  } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import headerStyles from './header.module.scss';

const Header = () => {

    const [isLoaded, setLoaded] = useState(false);

    const toggleSidebarMenu = () => {
        const mainLayout = document.querySelector('#mainLayout');
        const sidebarMenu = document.querySelector('#sidebarMenu');

        if(!isLoaded) {
            sidebarMenu.classList.toggle('isInvisible');
            setLoaded(true);
        }

        mainLayout.classList.toggle('menuIsOpen');
        mainLayout.classList.toggle('menuIsClosed');
        sidebarMenu.classList.toggle('isVisible');
        sidebarMenu.classList.toggle('isInvisible');
    }

    useEffect(() => {
        const mainLayout = document.querySelector('#mainLayout');
        mainLayout.classList.toggle('menuIsClosed');
    }, []);

    return (
        <div className={headerStyles.container}>
            <div className={headerStyles.wrapper}>
                <div className={headerStyles.logo}>
                    <h2><Link to='/'>Mint<span>Web</span>Tuts</Link></h2>
                </div>
                <div className={headerStyles.navigation}>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/books">Carti</Link></li>
                    <li><Link to="/about">Despre</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                <button className={headerStyles.openMenuButoon} onClick={toggleSidebarMenu} onKeyPress={toggleSidebarMenu}>
                    <FontAwesomeIcon icon={faBars}/>
                </button>
                </div>
            </div>
        </div>
    );
}
export default Header;