import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { bannerStatus } from './helper';
import cookieStyles from './cookie.module.scss';

const CookieBanner = () => {

    const [banner, setBannerStatus] = useState(bannerStatus.NOT_VISIBLE);

    const displayCookieBanner = () => {
        const data = window.localStorage.getItem('mwtbcookiebannerdisplay');
        if(!data) {
            setBannerStatus(bannerStatus.VISIBLE);
        }
    }

    const handleAgreedButton = () => {
        setBannerStatus(bannerStatus.NOT_VISIBLE);
        window.localStorage.setItem('mwtbcookiebannerdisplay', new Date());
    }

    useEffect(() => {
        displayCookieBanner();
    }, [])

    switch(banner) {
        case bannerStatus.NOT_VISIBLE:
            return null;
        case bannerStatus.VISIBLE:
        return (
            <React.Fragment>
            <div className={cookieStyles.cookiewrapper}>
                Acest site folosește module cookie pentru a vă asigura
                că beneficiați de ce mai bună experiență pe site-ul nostru. <Link to="/cookies">Vezi detalii</Link>
                <button className={cookieStyles.agreed} onClick={handleAgreedButton}>De acord</button>
            </div>
            <div className={cookieStyles.secondWrapper}>
            <div>Acest site folosește module cookie pentru a vă asigura
                că beneficiați de ce mai bună experiență pe site-ul nostru.</div>
                <Link to="/cookies">Vezi detalii</Link>
                <button onClick={handleAgreedButton}>De acord</button>
            </div>
            </React.Fragment>
        );
        default:
            return null; 
        }
}

export default CookieBanner;