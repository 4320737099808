import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import sidebarStyles from './sidebar.module.scss';

const SidebarMenu = (props) => {

    const closeSidemenu = () => {
        const mainLayout = document.querySelector('#mainLayout');
        const sidebarMenu = document.querySelector('#sidebarMenu');

        mainLayout.classList.toggle('menuIsOpen');
        mainLayout.classList.toggle('menuIsClosed');
        sidebarMenu.classList.toggle('isVisible');
        sidebarMenu.classList.toggle('isInvisible');
    }

    return (
        <div id="sidebarMenu" className={sidebarStyles.sidebarmenu}>
                <div className={sidebarStyles.closebuttoncontainer}>
                    <button className={sidebarStyles.closebutton} onClick={closeSidemenu} onKeyPress={closeSidemenu}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/books">Carti</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/about">About</Link></li>
                </ul>
            </div>
    );
}
export default SidebarMenu;